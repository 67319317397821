import React from 'react';
import error from '../../assets/images/error-404.png'
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LINK_HOME} from "../../routes/type";
import {APPNAME} from "../../helpers/utils";

function NotFound({t}) {
    document.title = APPNAME+"::"+ t('404_title')
    return (
        <section id="page-404" className="bg--white-300 division">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-md-9 col-lg-8">
                        <div className="page-404-txt text-center">
                            <div className="rel page-404-img">
                                <img className="img-fluid" src={error} alt="error-404-img"/>
                            </div>
                            <h2 className="s-56 w-700 color--dark">{t('404_title')}</h2>
                            <h6 className="s-22 color--grey">
                                {t('404_description')}
                            </h6>
                            <Link to={LINK_HOME} className="btn btn--theme hover--theme">{t('BackToHome')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withTranslation() (NotFound)
