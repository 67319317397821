import {Home} from "../views";
import {LINK_ABOUT, LINK_CAREERS, LINK_CONTACT, LINK_HOME, LINK_PORTFOLIO, LINK_SERVICE, LINK_TEAMS} from "./type";
import NotFound from "../views/NotFound/NotFound";
import About from "../views/About/About";
import Team from "../views/Team/Team";
import Contact from "../views/Contact/Contact";
import Portfolio from "../views/Portfolio/Portfolio";
import Careers from "../views/Careers/Careers";
import Services from "../views/Services/Services";
import ServiceDetail from "../views/Services/ServiceDetail";
import PortfolioDetail from "../views/Portfolio/Detail";

export const routes = [

    { path: LINK_HOME,  component : Home },
    { path: LINK_ABOUT,  component : About },
    { path: LINK_TEAMS,  component : Team },
    { path: LINK_PORTFOLIO,  component : Portfolio },
    { path: LINK_CONTACT,  component : Contact },
    { path: LINK_CAREERS,  component : Careers },
    { path: LINK_SERVICE,  component : Services },
    { path: "/service/:key",  component : ServiceDetail},
    { path: "/portfolio/:key",  component : PortfolioDetail},


    // 404
    { path: "*",  component : NotFound },
]
