import React from 'react';
import img05 from "./../../../assets/images/img-05.png";

const Section4 = () => {
    return (
        <>
            <section className="py-100 ct-01 content-section division">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 order-last order-md-2">
                            <div className="txt-block left-column wow fadeInRight">
                                <span className="section-id">Productivity Focused</span>
                                <h2 className="s-46 w-700">Achieve more with better workflows</h2>
                                <p>
                                    Sodales tempor sapien quaerat ipsum undo congue laoreet turpis
                                    neque auctor turpis vitae dolor luctus placerat magna and ligula
                                    cursus purus vitae purus an ipsum suscipit
                                </p>
                                <ul className="simple-list">
                                    <li className="list-item">
                                        <p>
                                            Sapien quaerat tempor an ipsum laoreet purus and sapien dolor
                                            an ultrice ipsum aliquam undo congue cursus dolor
                                        </p>
                                    </li>
                                    <li className="list-item">
                                        <p className="mb-0">
                                            Purus suscipit cursus vitae cubilia magnis volute egestas
                                            vitae sapien turpis ultrice auctor congue magna placerat
                                        </p>
                                    </li>
                                </ul>
                                <div className="txt-block-tra-link mt-25">
                                    <a href="#lnk-2" className="tra-link ico-20 color--theme">
                                        Get insights faster <span className="flaticon-next"/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 order-first order-md-2">
                            <div className="img-block right-column wow fadeInLeft">
                                <img
                                    className="img-fluid"
                                    src={img05}
                                    alt="content-image"
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </section>


        </>
    );
}

export default Section4;
