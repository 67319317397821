import React, {useEffect, useState} from 'react';
import { routes } from "./routes";
import {Switch, BrowserRouter as Router, Route, useHistory} from "react-router-dom";
import ScrollToTop from "./components/Scroll/ScrollToTop";
import Loader from "./components/Loaders/Loader";
import {Toaster} from "sonner";


function App() {
    const [loading, setLoading] = useState(true)


    useEffect(() => {
       setTimeout(() =>{
           setLoading(false)
       },1000)
    }, []);

  return (
      <div>
          {
              loading ?
                  <Loader/>
                  :
                      <Router >
                          <ScrollToTop history={Router.history}  />
                          <Switch >
                              {routes.map((route, idx) =>
                                  <Route exact  key={idx} path={route.path} component={route.component} />
                              )}
                          </Switch>
                      </Router>
          }

          <Toaster
              position="bottom-left"
              duration={5000}
              richColors={true}
              expand={true}
              toastOptions={{
                  style: {
                      height : "80px",
                      textAlign : "left",
                      float : "left"
                  },
                  className: 'class',
              }}
          />
      </div>
  );
}

export default App;
