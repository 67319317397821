import React, {Fragment} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import LangSwitcher from "../components/Lang/LangSwitcher";

const Layout = ({children}) => {


       const initial ={
        opacity : 0,
        }


    const final = {
        opacity : 1,
        }

    return (
        <>
            <div id="page" className="page font--jakarta">
                <LangSwitcher/>
                <Header/>

                {children}
                <hr/>
                <Footer/>
            </div>
        </>
    )
}
export default Layout;