import React from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../layouts/Layout";
import a21 from '../../assets/images/a2-1.jpg'
import a22 from '../../assets/images/a2-2.jpg'
import a23 from '../../assets/images/a2-3.jpg'
import a24 from '../../assets/images/a2-4.jpg'
import 'swiper/css';
import {APPNAME, FOUNDER, getData} from "../../helpers/utils";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";



const About = ({t}) => {
    document.title = APPNAME+" :: "+ t('About')
    return (
        <Layout>
            <section id="about-2" className="rel inner-page-hero about-section division">
                <div className="container">
                    <div className="row">
                        <div className="col-md-11 col-lg-10 col-xl-9">
                            <div className="about-2-title mb-60">
                                <h2 className="s-52 w-700 mb-30">{t('WhoWeAre')}</h2>
                                <p className="mb-0">
                                    {APPNAME} {t('InovSubText')}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">


                        <div className="col-md-5">
                            <div className="text-end">
                                <div className="about-2-img a-2-2 r-12 mb-30">
                                    <img className="img-fluid" src={a21} alt="about-image"/>
                                </div>
                                <div className="about-2-img a-2-2 r-12">
                                    <img className="img-fluid" src={a22} alt="about-image"/>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about-2-img a-2-3 r-12">
                                <img className="img-fluid" src={a23} alt="about-image"/>
                            </div>
                            <div className="row">
                                <div className="col-md-7 col-lg-6">
                                    <div className="a2-txt bg--primary-blue pattern-01 bg--fixed color--white r-12">
                                        <div className="a2-txt-quote ico-40 o-20">
                                            <span className="flaticon-quote"></span>
                                        </div>
                                        <p className="mt-65 mb-65">  {t('InovSubText')}</p>
                                        <p className="a2-txt-author">{FOUNDER} <span>{t('Founder')}</span></p>

                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-6">
                                    <div className="about-2-img a-2-4 r-12" style={{height: "370px"}}>
                                        <img className="img-fluid" src={a24} alt="about-image"/>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </section>
            <div id="about-3" className="pt-100 about-section division">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="a3-1" className="txt-block">
                                <h5 className="s-24 w-700 mb-20">{t('OurMissions')}</h5>
                                <p>
                                    {t('WeAre')}  {t('MissionText1')}
                                </p>
                                <ul className="simple-list">

                                    <li className="list-item">
                                        <p>
                                            {t('MissionText2')}
                                        </p>
                                    </li>

                                    <li className="list-item">
                                        <p>
                                            {t('MissionText3')}
                                        </p>
                                    </li>

                                </ul>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div id="a3-2" className="txt-block">
                                <h5 className="s-24 w-700 mb-20">{t('OurVision')}</h5>
                                <p>
                                    {t('VisionText1')}
                                </p>
                                <p className="mb-0">
                                    {t('VisionText2')}
                                </p>

                                <p className="mb-0">
                                    {t('VisionText3')}
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <section className="bg--04 bg--fixed py-100 ct-01 content-section division mt-5">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 order-last order-md-2">
                            <div className="txt-block left-column wow fadeInRight">
                                <span className="section-id">{t('Founder')}</span>
                                <h2 className="s-50 w-700">{FOUNDER}</h2>

                                <div className="a2-txt-quote ico-30 o-20">
                                    <span className="flaticon-quote"></span>
                                </div>
                                <br/>
                                <p>{t('FounderWord1')}</p>
                                <p>{t('At')} <b className="text-black">{APPNAME}</b> {t('FounderWord2')}</p>
                                <div className="text-end">
                                    <div className="a2-txt-quote ico-30 o-20">
                                        <span className="flaticon-quote"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-first order-md-2">
                        <div className="img-block j-img video-preview right-column wow fadeInLeft">
                                {/*<a className="video-popup2" href="https://www.youtube.com/embed/7e90gBu4pas">*/}
                                {/*    <div className="video-btn video-btn-xl bg--theme">*/}
                                {/*        <div className="video-block-wrapper"><span*/}
                                {/*            className="flaticon-play-button"></span></div>*/}
                                {/*    </div>*/}
                                {/*</a>*/}
                                <iframe width="500" height="520" src="https://www.youtube.com/embed/i52KSgyLa78"
                                        frameBorder="0"
                                        style={{borderRadius: "10px"}}
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {
                getData().partners.length > 0 ?
                    <div id="brands-1" className="py-80 brands-section">
                        <div className="container">


                            <div className="row justify-content-center">
                                <div className="col-md-10 col-lg-9">
                                    <div className="brands-title mb-50">
                                        <h3>{t('OurPartner')}</h3>
                                    </div>
                                </div>
                            </div>


                            <Swiper
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }
                                }
                                loop={true}
                                slidesPerView={6}
                                spaceBetween={50}
                            >
                                {
                                    getData().partners.map((item, k) =>

                                        <SwiperSlide key={k}>
                                            <div className="brand-logo">
                                                <a href="#"><img className="img-fluid light-theme-img"
                                                                 src={item.picture} alt="brand-logo"/></a>
                                                <a href="#"><img className="img-fluid dark-theme-img" src={item.picture}
                                                                 alt="brand-logo"/></a>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }

                            </Swiper>

                        </div>

                    </div>

                    : ""
            }


        </Layout>
    );
}

export default withTranslation()(About)
