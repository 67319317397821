import React from 'react';
import img6 from './../../../assets/images/img-06.png'
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LINK_SERVICE} from "../../../routes/type";
import {getData} from "../../../helpers/utils";

const Section2 = ({t}) => {
    return (
        <>
            <section id="features-2" className="pt-100 features-section division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-80">
                                <h2 className="s-50 w-700">{t('AllInOnePlace')}</h2>
                                <p className="s-21 color--grey">
                                    {t('WhatweOffer')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="fbox-wrapper text-center">
                        <div className="row row-cols-1 row-cols-md-3">

                            {
                                getData().services.slice(0,3).map((service,k) =>
                                    <div className="col" key={k}>
                                        <div className="fbox-2 fb-1 wow fadeInUp">

                                            <div className="fbox-txt">
                                                <h6 className="s-22 w-700">{service.name}</h6>
                                                <p>
                                                    {service.description.substring(0, 149)}...
                                                </p>

                                                <Link className="btn r-04  btn-sm mt-20 text-decoration-underline" to={'/service/'+service.key}>{t('ReadMore')}...</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="img-block-btn text-center wow fadeInUp mt-50 mb-50">
                                <Link to={LINK_SERVICE}
                                      className="btn r-04 btn--tra-black hover--theme btn-lg">{t('SeeMore')}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="pt-100 ws-wrapper content-section">
                <div className="container-fluid">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="bc-1-wrapper bg--02 bg--fixed r-16">
                            <div className="section-overlay">
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-6 order-last order-md-2">
                                        <div className="txt-block left-column wow fadeInRight">
                                            <span className="section-id">One-Stop Solution</span>
                                            <h2 className="s-46 w-700">
                                                Smart solutions, real-time results
                                            </h2>
                                            <ul className="simple-list">
                                                <li className="list-item">
                                                    <p>
                                                        Cursus purus suscipit vitae cubilia magnis volute egestas
                                                        vitae sapien turpis sodales magna
                                                    </p>
                                                </li>
                                                <li className="list-item">
                                                    <p className="mb-0">
                                                        Tempor sapien quaerat an ipsum laoreet purus and sapien
                                                        dolor an ultrice ipsum aliquam congue
                                                    </p>
                                                </li>
                                            </ul>
                                            <a
                                                href="#banner-13"
                                                className="btn btn-sm r-04 btn--theme hover--theme"
                                            >
                                                Get started now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 order-first order-md-2">
                                        <div className="img-block right-column wow fadeInLeft">
                                            <img
                                                className="img-fluid"
                                                src={img6}
                                                alt="content-image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default withTranslation()(Section2)
