import React from 'react';
import hero18 from "../../../assets/images/hero-18-img.png";
import {APPNAME} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";

const Section1 = ({t}) => {
    return (
           <section id="hero-18" className="bg--scroll hero-section">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 order-last order-md-2">
                            <div className="hero-18-img wow fadeInRight">
                                <img
                                    className="img-fluid"
                                    src={hero18}
                                    alt="hero-image"
                                    style={{
                                        width : '600px',
                                        height : '800px',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 order-first order-md-2">
                            <div className="hero-18-txt wow fadeInLeft">
                                <h4 className="w-700">
                                   <span className="s-40"> {APPNAME}</span>
                                </h4>
                                <p className="p-xl">
                                    {t('HeroText')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

    );
}

export default withTranslation()(Section1)
