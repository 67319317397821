import React from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../layouts/Layout";
import img16 from  '../../assets/images/img-16.png'
import {LINK_CAREERS} from "../../routes/type";
import {Link} from "react-router-dom";
import team1 from '../../assets/images/team-1.jpg'
import team2 from '../../assets/images/team-2.jpg'
import team3 from '../../assets/images/team-3.jpg'
import {APPNAME, getData} from "../../helpers/utils";


const Team = ({t}) => {
    document.title = APPNAME+" :: "+ t('Teams')
    return (
        <Layout>
            <section id="team-2" className="gr--whitesmoke pb-40 inner-page-hero team-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-80">
                                <h2 className="s-52 w-700">{t('Teams')}</h2>
                            </div>
                        </div>
                    </div>


                    <div className="team-members-wrapper">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                            {
                                getData().team.map((item,k) =>
                                    <div className="col" key={k}>
                                        <div id="tm-2-1" className="team-member mb-60 wow fadeInUp" >
                                            <div className="team-member-photo r-14">
                                                <div className="hover-overlay" style={{background : "#E4E4E4"}}>
                                                    <img className="img-fluid" src={item.picture} alt="team-member-foto"/>
                                                    <div className="item-overlay"></div>
                                                </div>
                                            </div>


                                            <div className="team-member-data">
                                                <h6 className="s-20 color--black w-700">{item.name}</h6>
                                                <p className="color--grey">{t(item.role)}</p>
                                                <ul className="tm-social ico-20 clearfix">
                                                    <li><a href={item.facebook} target="_blank"><span className="flaticon-facebook"></span></a></li>
                                                    <li><a href={item.linkedin} target="_blank"><span className="flaticon-linkedin-logo"></span></a></li>
                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>


                </div>
            </section>

            <section id="banner-12" className="banner-section">
                <div className="container">
                    <div className="banner-12-wrapper bg--05 bg--fixed r-16">
                        <div className="banner-overlay">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-7">
                                    <div className="banner-12-txt color--white">

                                        <h2 className="s-45 w-700">{t('Careers')}</h2>
                                        <p className="p-lg">
                                            {t('WeAreLookingFor')}
                                        </p>
                                        <Link to={LINK_CAREERS}
                                              className="btn r-04 btn--theme hover--tra-white">{t('SeeOpenRole')}</Link>
                                    </div>
                                </div>


                                <div className="col-md-5">
                                    <div className="banner-12-img text-center">
                                        <img className="img-fluid" src={img16} alt="banner-image"/>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </div>
            </section>

        </Layout>
    );
}

export default withTranslation()(Team)
