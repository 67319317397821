import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {APPNAME, getData} from "../../helpers/utils";
import Layout from "../../layouts/Layout";
import {Link, useParams} from "react-router-dom";
import {LINK_SERVICE} from "../../routes/type";


const ServiceDetail = ({t}) => {
    document.title = APPNAME+" :: "+ t('Services')
    const [detail, setDetail] = useState({})
    const r = useParams()

    useEffect(() => {
        setDetail(getData().services.filter(service => service.key === r.key)[0])
    }, [r.key]);



    return (
        <Layout>

            <section id="single-post" className="pb-90 inner-page-hero blog-page-section">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-xl-10">
                            <div className="post-content">

                                <div className="single-post-title text-center">
                                    <span className="post-tag color--green-400">Service</span>
                                    <h2 className="s-46 w-700">{detail?.name}</h2>


                                </div>

                                <div className="blog-post-img py-50">
                                    <img className="img-fluid r-16" src={detail?.picture} alt="blog-post-image"/>
                                </div>

                                <div className="single-post-txt">


                                    {

                                        detail?.detail?.map((content, k) =>

                                            <div key={k}>
                                                {
                                                    content.type == "text"

                                                        ? <div>
                                                            <h4 className="s-20 w-700">{content.title}</h4>
                                                            {
                                                                content.paragraph.map((paragraph, k) =>
                                                                    <p style={{fontSize : "15px"}} key={k}><b className="text-black">{paragraph.title}</b>  {!content.noBreak ? <br/> : ""} {paragraph.text}</p>
                                                                )

                                                            }
                                                        </div>
                                                        : ""
                                                }

                                                {
                                                    content.type == "picture"

                                                        ?
                                                        <div className="mt-100 mb-100">
                                                            <h4 className="s-20 w-700">{content.title}</h4>
                                                            <div className="row">
                                                                {
                                                                    content.pictures.map((pic, p) =>
                                                                        <div key={p} className={`col-lg-${12 / content.pictures.length}`}>
                                                                            <img className="img-fluid r-16"
                                                                                 src={pic?.picture} alt="blog-post-image" style={{width : "100%"}}/>
                                                                        </div>
                                                                    )

                                                                }
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                            </div>
                                        )

                                    }


                                </div>

                            </div>
                        </div>


                    </div>

                </div>

            </section>

            <section id="blog-1" className="bg--white-300 py-100 blog-section division">
                            <div className="container">

                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-9">
                                <div className="section-title mb-70">


                                    <h4 className="s-36 w-700">{t('OtherServices')}</h4>

                                </div>
                            </div>
                        </div>
                         <div className="row">
                             {
                                 getData().services.filter((s) => s.key != r.key).map((service, k) =>
                           <div className="col-md-6 col-lg-4 mb-40" key={k}>
                                      <div id="bp-1-1" className="blog-post wow fadeInUp">
                                <div className="blog-post-img mb-35">
                                    <Link to={"/service/" + service.key}>
                                        <img className="img-fluid r-16" style={{height: "250px", width: "100%"}}
                                             src={service.picture} alt="blog-post-image"/>
                                    </Link>
                                </div>

                                <div className="blog-post-txt">
                                    <h6 className="s-20 w-700">
                                        <Link to={"/service/"+service.key}>{service.name}</Link>
                                    </h6>
                                    <p>{service.description.substring(0,149)}...</p>
                                </div>

                            </div>
                                  </div>
                                 )}
                         </div>
                  </div>
            </section>

      </Layout>
    );
 }

export default withTranslation()(ServiceDetail)
