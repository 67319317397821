import React from 'react';
import {CustomChangeLanguage} from "../../helpers/utils";

const LangSwitcher = () => {

    return (
        <div className="lang-switcher">
            <div className="lang-switcher-item">
                <span
                    className={localStorage.getItem('I18N_LANGUAGE') == "fr" ? "active" : ""}
                    onClick={event => CustomChangeLanguage('fr')}
                >FR</span>
            </div>
            <div className="lang-switcher-item">
                <span
                    className={localStorage.getItem('I18N_LANGUAGE') == "en" ? "active" : ""}
                    onClick={event => CustomChangeLanguage('en')}
                >EN</span>
            </div>
        </div>)
}
export default LangSwitcher;