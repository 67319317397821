import React from 'react';
import img08 from '../../../assets/images/img-08.png'
import img09 from '../../../assets/images/img-09.png'
import img19 from '../../../assets/images/img-19.png'
import {withTranslation} from "react-i18next";
const Section5 = ({t}) => {
    return (
        <>
            <section className="pt-100 ct-01 content-section division">
                <div className="container">

                    <div className="row d-flex align-items-center">

                        <div className="col-md-6 order-last order-md-2">
                            <div className="txt-block left-column wow fadeInRight">

                                <div className="txt-box">

                                    <h5 className="s-24 w-700">{t('SolutionForYou')}</h5>

                                    <p>
                                        Sodales tempor sapien quaerat ipsum undo congue laoreet turpis
                                        neque auctor turpis vitae dolor luctus placerat magna and ligula
                                        cursus purus vitae purus an ipsum suscipit
                                    </p>
                                </div>

                                <div className="txt-box mb-0">

                                    <h5 className="s-24 w-700">Connect your data sources</h5>

                                    <p>
                                        Tempor sapien sodales quaerat ipsum undo congue laoreet turpis
                                        neque auctor turpis vitae dolor luctus placerat magna and ligula
                                        cursus purus an ipsum vitae suscipit purus
                                    </p>

                                    <ul className="simple-list">
                                        <li className="list-item">
                                            <p>
                                                Tempor sapien quaerat an ipsum laoreet purus and sapien
                                                dolor an ultrice ipsum aliquam undo congue dolor cursus
                                            </p>
                                        </li>
                                        <li className="list-item">
                                            <p className="mb-0">
                                                Cursus purus suscipit vitae cubilia magnis volute egestas
                                                vitae sapien turpis ultrice auctor congue magna placerat
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6 order-first order-md-2">
                            <div className="img-block right-column wow fadeInLeft">
                                <img
                                    className="img-fluid"
                                    src={img08}
                                    alt="content-image"
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </section>


            <section className="pt-100 ct-02 content-section division">
                <div className="container">

                    <div className="row d-flex align-items-center">

                        <div className="col-md-6">
                            <div className="img-block left-column wow fadeInRight">
                                <img
                                    className="img-fluid"
                                    src={img09}
                                    alt="content-image"
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="txt-block right-column wow fadeInLeft">

                                <div className="cbox-4">

                                    <div className="box-title">
                                        <span className="flaticon-paper-sizes color--theme"/>
                                        <h5 className="s-24 w-700">Built-in automation</h5>
                                    </div>

                                    <div className="cbox-4-txt">
                                        <p>
                                            Quaerat sodales sapien blandit purus primis purus ipsum
                                            cubilia laoreet augue luctus and magna dolor luctus egestas an
                                            ipsum sapien primis vitae volute and magna turpis
                                        </p>
                                    </div>
                                </div>

                                <div className="cbox-4">

                                    <div className="box-title">
                                        <span className="flaticon-layers-1 color--theme"/>
                                        <h5 className="s-24 w-700">Automatic workflows</h5>
                                    </div>

                                    <div className="cbox-4-txt">
                                        <p>
                                            Quaerat sodales sapien blandit purus primis purus ipsum
                                            cubilia laoreet augue luctus and magna dolor luctus egestas an
                                            ipsum sapien primis vitae volute and magna turpis
                                        </p>
                                    </div>
                                </div>

                                <div className="cbox-4">

                                    <div className="box-title">
                                        <span className="flaticon-pie-chart color--theme"/>
                                        <h5 className="s-24 w-700">Real-time analytics</h5>
                                    </div>

                                    <div className="cbox-4-txt">
                                        <p className="mb-0">
                                            Quaerat sodales sapien blandit purus primis purus ipsum
                                            cubilia laoreet augue luctus and magna dolor luctus egestas an
                                            ipsum sapien primis vitae volute and magna turpis
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
}

export default  withTranslation()(Section5)
