import React from 'react';
import {withTranslation} from "react-i18next";
import {APPNAME, getData} from "../../helpers/utils";
import Layout from "../../layouts/Layout";
import project01 from "../../assets/images/projects/project-01.jpg"
import project02 from "../../assets/images/projects/project-02.jpg"
import {Link} from "react-router-dom";


const Portfolio = ({t}) => {
    document.title = APPNAME+" :: "+ t('Portfolio')
    return (
        <Layout>

            <section id="projects-1" className="inner-page-hero projects-section">
                <div className="container">


                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-80">
                                <h2 className="s-52 w-700">{t('OurWorks')}</h2>
                                <p className="s-21 color--grey">{t('PortfolioTextIntro')}</p>
                            </div>
                        </div>
                    </div>


                    <div className="projects-wrapper">
                        <div className="row align-items-center row-cols-1 row-cols-md-3">

                            {
                                getData().portfolio.map((item,k) => (
                                    <div className="col" key={k}>
                                        <div id="pt-1-1" className="project-details">


                                            <h5 className="s-24">{item.name}</h5>


                                            <div className="project-preview r-10">


                                                <div className="hover-overlay">
                                                    <img className="img-fluid" src={item.picture} alt="project-preview"/>
                                                    <div className="item-overlay"></div>
                                                </div>


                                                <div className="project-link ico-35 color--white">
                                                    <Link to={`/portfolio/${item.slug}`} title=""><span
                                                        className="flaticon-visibility"></span></Link>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>


                </div>
            </section>

            <div className="py-100 page-pagination theme-pagination">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="Page navigation">
                                <ul className="pagination ico-20 justify-content-center">
                                    <li className="page-item disabled"><a className="page-link" href="#"
                                                                          tabIndex="-1"><span
                                        className="flaticon-back"></span></a>
                                    </li>
                                    <li className="page-item active" aria-current="page"><a className="page-link"
                                                                                            href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item"><a className="page-link" href="#" aria-label="Next"><span className="flaticon-next"></span></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    );
}

export default withTranslation()(Portfolio)
