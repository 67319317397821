import React, {useEffect, useState} from 'react';

const Switcher = () => {
    const [isDark, setIsDark] = useState(localStorage.getItem('isDark')??"light")
    const dd = document.getElementsByTagName('body')[0];

    useEffect(() => {
        if (localStorage.getItem('isDark') === "dark") dd.classList.add('theme--dark');
    }, []);


     const switcher = (value) => {
        setIsDark(value)

         if (!value) {
             localStorage.setItem('isDark',"dark")
             dd.classList.add('theme--dark');
         } else {
             localStorage.setItem('isDark',"light")
             dd.classList.remove('theme--dark');
         }
     }


    return (
            <div className="switcher-theme">
                <input type="checkbox" id="hide-checkbox" defaultChecked={isDark === "light"}  onClick={event => switcher(event.target.checked)}/>
                <label htmlFor="hide-checkbox" className="toggle">
                    <span className="toggle-button">
                      <span className="crater crater-1"></span>
                      <span className="crater crater-2"></span>
                      <span className="crater crater-3"></span>
                      <span className="crater crater-4"></span>
                      <span className="crater crater-5"></span>
                      <span className="crater crater-6"></span>
                      <span className="crater crater-7"></span>
                    </span>
                    <span className="star star-1"></span>
                    <span className="star star-2"></span>
                    <span className="star star-3"></span>
                    <span className="star star-4"></span>
                    <span className="star star-5"></span>
                    <span className="star star-6"></span>
                    <span className="star star-7"></span>
                    <span className="star star-8"></span>
                </label>
            </div>
    );
}

export default Switcher;
