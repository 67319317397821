import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../layouts/Layout";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {toast} from "sonner";
import axios from "axios";
import {Link} from "react-router-dom";
import {LINK_POLICY} from "../../routes/type";


const Contact = ({t}) => {

    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        fullname: Yup.string().required(t('FullnameRequired')),
        email: Yup.string().required(t('EmailRequired')),
        subject: Yup.string().required(t('SubjectRequired')),
        message: Yup.string().required(t('MessageRequired')),
    });

    const initialValues = {
        fullname: '',
        email: '',
        subject: '',
        message: '',
    }

    const handleSubmit = async (values,{resetForm}) => {
        setLoading(true)
        await axios.post("https://technctrl.vercel.app/api/v1/contact",values).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                resetForm({values : ""})
                toast.success(response.data.message)
            }else{
                toast.error(response.data.message)
            }
        })
    }


    return (
        <Layout>
            <section id="contacts-1" className="pb-50 inner-page-hero contacts-section division">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title text-center mb-80">

                                <h2 className="s-52 w-700">{t('ContactUs')}</h2>

                                <p className="p-lg">
                                    {t('ContactUsText')}
                                </p>

                            </div>
                        </div>
                    </div>



                    <div className="row justify-content-center">
                        <div className="col-md-11 col-lg-10 col-xl-8">
                            <div className="form-holder">
                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                {({ errors, touched }) =>
                                <Form className="row contact-form">

                                    <div className="col-md-12 input-subject">
                                        <p className="p-lg">{t('ContactSubject')}</p>
                                        <span>{t('ContactSubjectText')}</span>
                                        <Field as="select" name="subject" className="form-select subject" aria-label="Default select example">
                                            <option selected value="">{t('ContactSubject')}...</option>
                                            <option value="test">Registering/Authorising</option>
                                            <option>Using Application</option>
                                            <option>Troubleshooting</option>
                                            <option>Backup/Restore</option>
                                            <option>Other</option>
                                        </Field>
                                        {errors.subject && touched.subject ? (
                                            <span className='text-danger'>{errors.subject}</span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-12">
                                        <p className="p-lg">{t('ContactName')}</p>
                                        <span>{t('ContactNameText')} </span>
                                        <Field type="text" name="fullname" className="form-control name" placeholder={t('ContactName')} />
                                        {errors.fullname && touched.fullname ? (
                                            <span className='text-danger'>{errors.fullname}</span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-12">
                                        <p className="p-lg">{t('ContactEmail')}</p>
                                        <span>{t('ContactEmailText')}</span>
                                        <Field type="text" name="email" className="form-control email" placeholder={t('ContactEmail')} />
                                        {errors.email && touched.email ? (
                                            <span className='text-danger'>{errors.email}</span>
                                        ) : null}
                                    </div>

                                    <div className="col-md-12">
                                        <p className="p-lg">{t('ContactMessage')}</p>
                                        <span>{t('ContactMessageText')}</span>
                                        <Field as="textarea" className="form-control message" name="message" rows="6" placeholder="..."/>
                                        {errors.message && touched.message ? (
                                            <span className='text-danger'>{errors.message}</span>
                                        ) : null}
                                    </div>


                                    <div className="col-md-12 mt-15 form-btn text-right">
                                        <button type="submit" className="btn btn--theme hover--theme submit" disabled={loading}>
                                            {
                                                loading ?
                                                    <>{t('Submitting')}...</>
                                                    :
                                                    <>{t('SubmitRequest')}</>
                                            }
                                        </button>
                                    </div>

                                    <div className="contact-form-notice">
                                        <p className="p-sm">{t('PolicyFormAccept')} <Link to={LINK_POLICY}>{t('Policy')}</Link>.
                                        </p>
                                    </div>


                                </Form>
                                }

                            </Formik>
                            </div>
                        </div>
                    </div>



                </div>

            </section>
        </Layout>
    );
}

export default withTranslation()(Contact)
