import React from 'react';
import {withTranslation} from "react-i18next";
import {APPNAME, getData} from "../../helpers/utils";
import Layout from "../../layouts/Layout";
import {Link} from "react-router-dom";


const Services = ({t}) => {
    document.title = APPNAME+" :: "+ t('Services')
    return (
        <Layout>
            <section id="blog-1" className="bg--white-300 py-100 blog-section division" style={{paddingTop : "160px"}}>
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">


                                <h4 className="s-36 w-700">{t('Services')}</h4>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            getData().services.map((service, k) =>
                                <div className="col-md-6 col-lg-4 mb-40" key={k}>
                                    <div id="bp-1-1" className="blog-post wow fadeInUp">
                                        <div className="blog-post-img mb-35">
                                            <Link to={"/service/" + service.key}>
                                                <img className="img-fluid r-16" style={{height: "250px", width: "100%"}}
                                                     src={service.picture} alt="blog-post-image"/>
                                            </Link>

                                        </div>

                                        <div className="blog-post-txt">
                                        <h6 className="s-20 w-700">
                                                <Link to={"/service/" + service.key}>{service.name}</Link>
                                            </h6>
                                            <p>{service.description.substring(0, 149)}...</p>
                                        </div>

                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default withTranslation()(Services)
