import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import logo from '../assets/logo.png'
import {LINK_ABOUT, LINK_CONTACT, LINK_HOME, LINK_PORTFOLIO, LINK_SERVICE, LINK_TEAMS} from "../routes/type";
import {ActiveMenu, getData} from "../helpers/utils";
import Switcher from "../components/Switcher/Switcher";

const Header = ({t}) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <header
            id="header"
            className="tra-menu navbar-dark light-hero-header white-scroll"
        >
            <div className="header-wrapper">
                <div className="wsmobileheader clearfix">
                  <span className="smllogo">
                    <img src={logo} alt="mobile-logo"/>
                  </span>
                    <a id="wsnavtoggle" className="wsanimated-arrow">
                        <span/>
                    </a>
                </div>
                <div className={`wsmainfull  menu clearfix ${scrollPosition > 80 ? 'scroll' : ''}`}>
                    <div className="wsmainwp clearfix">
                        <div className="desktoplogo">
                            <Link to={LINK_HOME} className="logo-black">
                                <img
                                    className="light-theme-img"
                                    src={logo}
                                    alt="logo"
                                />
                                <img
                                    className="dark-theme-img"
                                    src={logo}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="desktoplogo">
                            <Link to={LINK_HOME} className="logo-white">
                                <img src={logo} alt="logo"/>
                            </Link>
                        </div>

                        <nav className="wsmenu clearfix">
                            <ul className="wsmenu-list nav-theme">

                                <li className={`nl-simple ${ActiveMenu(LINK_HOME)}`}>
                                    <Link to={LINK_HOME} className="h-link">
                                        {t('Home')}
                                    </Link>
                                </li>

                                <li className={`nl-simple ${ActiveMenu(LINK_ABOUT)}`} >
                                    <Link to={LINK_ABOUT} className="h-link">
                                        {t('WhoWeAre')}
                                    </Link>
                                </li>

                                <li  className="mg_link">
                                    <Link to={LINK_SERVICE}  className="h-link">
                                        {t('Services')} <span className="wsarrow"/>
                                    </Link>
                                    <div className="wsmegamenu w-75 clearfix">
                                        <div className="container">
                                            <div className="row">

                                                <ul className="col-md-12 col-lg-4 link-list">
                                                    {
                                                        getData().services.slice(0,3).map((service,k) =>
                                                    <li className="fst-li">
                                                        <Link  to={'/service/'+service.key}>{service.name}</Link>
                                                    </li>
                                                        )}
                                                </ul>

                                                <ul className="col-md-12 col-lg-4 link-list">
                                                    {
                                                        getData().services.slice(3,6).map((service,k) =>
                                                            <li className="fst-li">
                                                                <Link  to={'/service/'+service.key}>{service.name}</Link>
                                                            </li>
                                                        )}
                                                </ul>

                                                <ul className="col-md-12 col-lg-4 link-list">
                                                    {
                                                        getData().services.slice(6,9).map((service,k) =>
                                                            <li className="fst-li">
                                                                <Link  to={'/service/'+service.key}>{service.name}</Link>
                                                            </li>
                                                        )}
                                                </ul>

                                            </div>
                                        </div>

                                    </div>
                                </li>

                                <li className={`nl-simple ${ActiveMenu(LINK_PORTFOLIO)}`} >
                                    <Link to={LINK_PORTFOLIO} className="h-link">
                                        {t('Portfolio')}
                                    </Link>
                                </li>

                                <li className={`nl-simple ${ActiveMenu(LINK_TEAMS)}`} >
                                    <Link to={LINK_TEAMS} className="h-link">
                                        {t('Teams')}
                                    </Link>
                                </li>



                                <li  className={`nl-simple ${ActiveMenu(LINK_CONTACT)}`} >
                                    <Link  to={LINK_CONTACT} className="h-link" style={{paddingTop : "7px"}}>
                                        {t('Contact')}
                                    </Link>
                                </li>

                                <li>
                                    <Switcher />
                                </li>

                            </ul>
                        </nav>

                    </div>
                </div>

            </div>

        </header>


    );
}

export default withTranslation()(Header)
