import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {APPNAME, format_date, getData} from "../../helpers/utils";
import Layout from "../../layouts/Layout";
import {Link, useParams} from "react-router-dom";
import {LINK_PORTFOLIO} from "../../routes/type";


const PortfolioDetail = ({t}) => {
    document.title = APPNAME+" :: "+ t('PortfolioDetail')

    const [detail, setDetail] = useState({})
    const r = useParams()
    
    useEffect(() => {
        setDetail(getData().portfolio.filter(port  => port.slug === r.key)[0])
    }, []);
    
    return (
        <Layout>
          
            <section id="project-1" className="gr--whitesmoke inner-page-hero single-project">
                <div className="container">
                    <div className="row justify-content-center">


                    
                        <div className="col-lg-11 col-xl-10">
                            <div className="project-description">


                          
                                <div className="project-title">

                        
                                    <h2 className="s-52 w-700">{detail?.name}</h2>


                                    <div className="project-data">
                                        <div className="row">

                                            <div className="col-lg-6">
                                                <br/>
                                                <p className="p-lg"><b>{t('Category')} :</b> {detail?.category}</p>
                                            </div>

                                            <div className="col-lg-3">
                                                <br/>
                                                <p className="p-lg"><b>{t('Started_at')} :</b> <small>{format_date(detail?.started_at)}</small></p>
                                            </div>


                                            <div className="col-lg-3">
                                                <br/>
                                                <p className="p-lg"><b>{t('Handover')} :</b> <small>{format_date(detail?.end_at)}</small></p>
                                            </div>

                                            <div className="col-lg-4">
                                                <br/>
                                                <p className="p-lg"><b>{t('Customer')} :</b> {detail?.customer}</p>
                                            </div>

                                            <div className="col-lg-4">
                                                <br/>
                                                <p className="p-lg"><b>{t('Link')} :</b> <em><a href={detail?.link} target="_blank" className="color--theme">{detail?.link}</a></em>
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            
                                <div className="project-priview-img mb-50 portfolio-detail-img">
                                    <img className="img-fluid r-16" src={detail?.picture} alt="project-preview" />
                                </div>


                               
                                <div className="project-txt">

                                    {

                                        detail?.detail?.map((content, k) =>

                                            <div key={k}>
                                                {
                                                    content.type == "text"

                                                        ? <div>
                                                            <h4 className="s-20 w-700">{content.title}</h4>
                                                            {
                                                                content.paragraph.map((paragraph, k) =>
                                                                    {
                                                                        if (paragraph.type == "h2"){
                                                                            return <h2 key={k} className="s-20 w-700">{paragraph.text}</h2>
                                                                        }
                                                                        else if (paragraph.type == "p") {
                                                                            return <p style={{fontSize: "15px"}} key={k}><b className="text-black">{paragraph.title}</b> {!content.noBreak ? <br/> : ""} {paragraph.text}</p>
                                                                        }
                                                                        else if (paragraph.type == "blockquote") {
                                                                            return <blockquote style={{fontSize: "15px"}} key={k}><b className="text-black">{paragraph.title}</b> {!content.noBreak ? <br/> : ""} {paragraph.text}</blockquote>
                                                                        }
                                                                    }
                                                                )

                                                            }
                                                        </div>
                                                        : ""
                                                }


                                                {
                                                    content.type == "video"

                                                        ?
                                                        <div className="project-image project-inner-img video-preview mt-50">


                                                            <a className="video-popup1"
                                                               href={content.link}>
                                                                <div className="video-btn video-btn-xl bg--pink-400">
                                                                    <div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
                                                                </div>
                                                            </a>


                                                            <img className="img-fluid r-10" src={content.picture} alt="video-preview"/>

                                                        </div>
                                                        : ""
                                                }


                                                {
                                                    content.type == "list" ?

                                                        <div className="mt-50">
                                                            <h2 className="s-20 w-700">{content.title}</h2>
                                                             <br/>
                                                            <ul className="simple-list">
                                                                {
                                                                    content.list.map((li, l) =>
                                                                        <li className="list-item" key={l}>
                                                                            <p style={{fontSize: "15px"}}>
                                                                                <b className="text-black"> {li.title}</b> {li.text}
                                                                            </p>
                                                                        </li>
                                                                    )

                                                                }

                                                            </ul>
                                                        </div>

                                                        : ""
                                                }


                                                {
                                                    content.type == "picture"

                                                        ?
                                                        <div className="pb-50">
                                                            <h2 className="s-20 w-700 mt-20">{content.title}</h2>
                                                             <p>{content.text}</p>
                                                            <div className="row">
                                                                {
                                                                    content.pictures.map((pic, p) =>

                                                                        <div className="project-inner-img mt-30 col-lg-6" key={p}>
                                                                            <div className="project-image project-preview r-10">

                                                                                <div className="hover-overlay">
                                                                                    <img className="img-fluid"
                                                                                         src={pic.picture}
                                                                                         alt="project-preview"/>
                                                                                    <div className="item-overlay"></div>
                                                                                </div>

                                                                                <div
                                                                                    className="project-link ico-35 color--white">
                                                                                    <a className="image-link"
                                                                                       href={pic.picture} title="">
                                                                                        <span
                                                                                            className="flaticon-visibility"></span>
                                                                                    </a>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                            </div>
                                        )

                                    }

                                </div>


                                <div className="more-projects ico-25 text-end pb-100">
                                    <Link to={LINK_PORTFOLIO}>
                                        <h3 className="s-38 w-700">{t('Portfolio')}</h3><span
                                        className="flaticon-next"></span>
                                    </Link>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </section>


        </Layout>
    );
}

export default withTranslation()(PortfolioDetail)
