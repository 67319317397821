import React from 'react';
import logo from '../assets/logo.png'
import {APPNAME, getData} from "../helpers/utils";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LINK_ABOUT, LINK_LEGAL_MENTION, LINK_POLICY, LINK_PORTFOLIO, LINK_SERVICE} from "../routes/type";
const Footer = ({t}) => {
    return (
     <footer id="footer-3" className="pt-100 footer">

                <div className="container">

                    <div className="row">

                        <div className="col-xl-3">
                            <div className="footer-info">
                                <img
                                    className="footer-logo"
                                    src={logo}
                                    alt="footer-logo"
                                />
                                <img
                                    className="footer-logo-dark"
                                    src={logo}
                                    alt="footer-logo"
                                />

                                <p>
                                    <br/>
                                    {t("InovText")}
                                </p>
                            </div>


                        </div>

                        <div className="col-sm-4 col-md-3 col-xl-2">
                            <div className="footer-links fl-1">

                                <h6 className="s-17 w-700">{t('Company')}</h6>

                                <ul className="foo-links clearfix">
                                    <li>
                                        <p>
                                            <Link to={LINK_ABOUT}>{t('WhoWeAre')}</Link>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <Link to={LINK_SERVICE}>Services</Link>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <Link to={LINK_PORTFOLIO}>Portfolio</Link>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-4 col-md-3 col-xl-2">
                            <div className="footer-links fl-2">

                            <h6 className="s-17 w-700">{t('OurProduct')}</h6>

                                <ul className="foo-links clearfix">
                                    {
                                        getData().app.map((item, k) =>
                                            <li key={k}>
                                                <p>
                                                    <a href={item.link}
                                                       target="_blank">{item.name}</a>
                                                </p>
                                            </li>
                                        )
                                    }

                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-4 col-md-3 col-xl-2">
                            <div className="footer-links fl-3">

                                <h6 className="s-17 w-700">Legal</h6>

                                <ul className="foo-links clearfix">
                                    <li>
                                        <p>
                                            <Link to={LINK_LEGAL_MENTION} >{t('LegalMention')}</Link>
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            <Link to={LINK_POLICY} >{t('Policy')}</Link>
                                        </p>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-3">
                            <div className="footer-links fl-4">
                                <h6 className="s-17 w-700">{t('ConnectWithUs')}</h6>
                                <p className="footer-mail-link ico-25">
                                    <a href={`mailto:${getData().mail}`}>{getData().mail}</a>
                                </p>

                                <ul className="footer-socials ico-25 text-center clearfix">
                                    <li>
                                        <a href={getData().facebook} target="_blank">
                                            <span className="flaticon-facebook"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a  href={getData().linkedin} target="_blank">
                                            <span className="flaticon-linkedin-logo"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a  href={getData().youtube} target="_blank">
                                            <span className="flaticon-youtube"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <hr/>
                    <div className="bottom-footer">
                        <div className="row row-cols-1 row-cols-md-2 d-flex align-items-start">
                            <div className="col">
                                <div className="bottom-secondary-link ico-15 text-start">
                                    <p className="p-sm">
                                        <a href="">
                                            Conçu par {APPNAME}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    );
}

export default withTranslation()(Footer)
