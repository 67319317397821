import React from 'react';
import Layout from "../../layouts/Layout";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import {APPNAME} from "../../helpers/utils";
import {withTranslation} from "react-i18next";

const Home = ({t}) => {

    document.title = APPNAME+" :: "+ t('Home')

    return (
        <Layout>

               <Section1 />

                <hr className="divider" />

               <Section2 />

               <Section3 />

                <Section4 />



                <hr className="divider" />

               <Section5 t={t} />


        </Layout>
    )
}

export default withTranslation()(Home)
