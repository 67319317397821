import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../layouts/Layout";
import {APPNAME, getData} from "../../helpers/utils";


const Careers = ({t}) => {
    document.title = APPNAME+" :: "+ t('Careers')

    const [option, setOption] = useState("")
    const [careers, setCareers] = useState([])
    const [currentcareers, setCurrentCareers] = useState([])
    const [searched, setSearched] = useState(false)

    useEffect(() => {
       setCareers(getData().careers)
        setCurrentCareers(getData().careers)
    }, [])

    const filterCareers = (event) => {
     setOption(event)
        setSearched(true)
     if (event == "all"){
         setCurrentCareers(careers)
         setSearched(false)
     }else{
         const filter = careers.filter((item) => item.category === parseInt(event))
         setCurrentCareers(filter)
     }


    }


    return (
        <Layout>
            <section id="careers-1" className="pb-60 careers-section " style={{paddingTop : "160px"}}>
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-title mb-70">
                                <h2 className="s-52 w-700">{t('Careers')}</h2>
                                <p className="p-lg">{t('CareersText')}</p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="position-relative filter-container d-flex">
                                <select name="" id="" className="form-select" style={{width : "150px", marginBottom : "20px"}} value={option} onChange={event => filterCareers(event.target.value)}>
                                    <option value="" disabled={true}>{t('Professions')}</option>
                                    <option value="all" >{t('All')}</option>
                                    {
                                        getData().careerOption.map((item,k) => <option key={k} value={item.id}>{item.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <hr/>
                        </div>
                    </div>


                        {
                          currentcareers.length > 0 ?
                              <div className="row row-cols-1 row-cols-md-2">
                                  {
                                      currentcareers.map((item, k) =>
                                          <div className="col" key={k}>
                                              <div className="role-box bg--white-300 r-10">
                                                  <a href="career-role.html">
                                                      <span>{item.categoryName}</span>
                                                      <h6 className="s-20 w-700">{item.title}</h6>
                                                      <p>Expérience : {item.exp} ans / {item.type}</p>
                                                  </a>
                                              </div>
                                          </div>
                                      )
                                  }
                              </div>
                              :
                              <div className='row mt-5 mb-5'>
                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="mt-4 text-center">
                                            <h3>{t('EmptyList')}</h3>

                                            {
                                                searched ?
                                                    <p>{t('EmptyListTextSearch')}</p>
                                                    :
                                                    <p>{t('EmptyListText')}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }


                </div>
            </section>
        </Layout>
    );
}

export default withTranslation()(Careers)
