import data from '../data/database.json'
import i18n from "i18next";

export const APPNAME = "TechNctrl"
export const FOUNDER = "PIOU Hassan"

export  const getData =  () =>{
    return  data
}

export const ActiveMenu = (path) =>{
    if (window.location.pathname === path) return "active"
}

export const CustomChangeLanguage = lang => {
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    return lang
}

const  padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export const  format_date = (date)  => {

    const dat = new Date(date)
    return [
        padTo2Digits(dat.getDate()),
        padTo2Digits(dat.getMonth() + 1),
        dat.getFullYear(),
    ].join('/');
}
